@import '_fonts';

* {
    margin: 0;
    padding: 0;
    border: none;
    list-style: none;
    box-sizing: border-box;

    color: black;
    font-family: 'TildaSans-VF';
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &::before,
    &::after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    &:focus,
    &:active {
        outline: none;
    }
}

// html {
//     scroll-behavior: smooth;
//     scroll-padding-top: 90px;

//     @media (max-width: 767px) {
//         scroll-padding-top: 60px;
//     }
// }

a,
a:link,
a:visited,
a:hover {
    text-decoration: none;
}

ul,
ul li {
    list-style: none;
}

button,
input {
    box-shadow: none;
    background: transparent;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input::-ms-clear {
    display: none;
}

label {
    cursor: pointer;
}
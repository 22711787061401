.header {
    width: 100%;
    height: 60px;
    padding: 5px 0 5px 40px;

    display: flex;

    @media (min-width: 768px) {
        padding: 5px 0 5px 100px;
    }

    @media (min-width: 1025px) {
        padding: 10px 0 0 40px;
        justify-content: center;
    }

    div {
        position: relative;

        @media (min-width: 1025px) {
            &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: -42.5px;

                width: 0px;
                height: 0px;
                border-top: 20px solid #fff;
                border-left: 70px solid transparent;
                border-right: 70px solid transparent;
            }
        }

        img {
            position: relative;
            z-index: 1;
            width: 50px;
            height: 50px;
            margin-bottom: -4px;

            @media (min-width: 1025px) {
                width: 55px;
                height: 55px;
            }
        }
    }
}